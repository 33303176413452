import Vue from 'vue';
import router from './router/index.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; 
import App from './App.vue'
import VueI18n from 'vue-i18n';
import lang from '@/lang/lang.js';


Vue.use(ElementUI);
Vue.use(VueI18n);
Vue.config.productionTip = false;

function parseURLParams() {
  const urlString = window.location.href;
  const url = new URL(urlString);

  const searchParams = new URLSearchParams(url.search);
  const encodedData = searchParams.get("userinfo");
  // 如果存在编码后的数据，则解码并解析为JSON对象；否则，将jsonData设为空对象
  const jsonData = encodedData ? JSON.parse(decodeURIComponent(encodedData)) : {};

  const defaultValues = {
    language: "English",
    appid: 20,
    userid: 123,
    appVersion: "appVersion",
    platformName: "platformName",
    deviceName: "deviceName",
    deviceInfo: "deviceInfo",
    deviceVersion: "deviceVersion",
    deviceID: "deviceID"
  };

  // 存储解析后的数据的对象
  const storedData = {};
  // 获取默认值对象的所有键
  const defaultKeys = Object.keys(defaultValues);
  for (let i = 0; i < defaultKeys.length; i++) {
    const key = defaultKeys[i];
    // 优先使用jsonData中的对应值，然后是cookie中的对应值，最后是默认值
    storedData[key] = jsonData[key] || getCookieValue(key) || defaultValues[key];

    // 删除具有其他路径的同名Cookie
    document.cookie = key + "=; path=/";
    // 设置cookie，将键值对存储到cookie中
    document.cookie = `${key}=${storedData[key]}; path=/`;
  }
  // console.log(storedData);
}
parseURLParams();

function getCookieValue(cookieName) {
  const cookies = document.cookie.split(";").map(cookie => cookie.trim());
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const [name, value] = cookie.split("=");
    if (name === cookieName) {
      return value;
    }
  }
  return null;
}

// 获取存储的数据
const language = getCookieValue('language');
// console.log(language);

function setFavicon() {
  const appid = getCookieValue('appid');
  const faviconLink = document.querySelector('link[rel="icon"]');
  if (appid==48 || appid==44) {
    faviconLink.href = '/a_favicon.jpg';
  } else if (appid==69 || appid==50) {
    faviconLink.href = '/cove_favicon.jpg';
  }
}
setFavicon();

const i18n = new VueI18n({
  locale: language, // 默认语言
  messages: lang,
});
// 在 Element UI 中配置 i18n
ElementUI.i18n((key, value) => i18n.t(key, value));

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
